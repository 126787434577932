/* eslint-disable indent */
import { takeLatest, call, put, select } from "redux-saga/effects";

import { getApiCall } from "../../utils/request";
import { validateFormData } from "../../utils/validation";
import { readStorage, writeStorage, emptyStorage } from "../../utils/common";

import {
  CHECK_LOGIN_STATUS,
  LOGOUT,
  LOGIN,
  REGISTER,
  REQUEST_EMAIL_VERIFY,
  VERIFY,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_PLAN,
  ADD_TO_CART,
  CHECKOUT,
  CANCEL_TRANS,
  PAYMENT_RESPONSE,
  GET_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  GET_TRANSACTION,
  GET_SUBSCRIPTION,
  GET_USAGE,
  GET_MY_MEETINGS,
  GET_SHARED_MEETINGS,
  RESET_FLOW,
  UPLOAD_MEETING,
  GET_MY_MEETING,
  RESET_SINGLE_MEETING,
  DOWNLOAD_FILE,
  UPDATE_MEETING,
  UPDATE_SPEAKER,
} from "./constants";

import {
  success,
  failure,
  failedValidation,
  logout,
  getProfile,
  getSubscription,
  getUsage,
} from "./actions";

import { TOP_UP_PLANS, VALIDITY_PLANS } from "./internalConfig";

import { initialState } from "./initialState";

import {
  makeSelectAppFormValue,
  makeSelectAppUserToken,
  makeSelectAppCurrentSubscription,
  makeSelectAppUserDetails,
  makeSelectAppCheckoutJourney,
} from "./selectors";
// import { initialState } from "./initialState";

function* checkLoginStatusCall() {
  try {
    const userTokenStringed = yield call(readStorage, "userToken");
    const userDetailStringed = yield call(readStorage, "userDetails");
    const currentUsagelStringed = yield call(readStorage, "currentUsage");
    const currSubString = yield call(readStorage, "currentSubscription");
    const checkoutJourneyString = yield call(readStorage, "checkoutJourney");

    const userToken = userTokenStringed
      ? JSON.parse(userTokenStringed)
      : initialState.userToken;
    const userDetails = userDetailStringed
      ? JSON.parse(userDetailStringed)
      : { ...initialState.userDetails };
    const currentUsage = currentUsagelStringed
      ? JSON.parse(currentUsagelStringed)
      : { ...initialState.currentUsage };
    const currentSubscription = currSubString
      ? JSON.parse(currSubString)
      : [...initialState.currentSubscription];
    const checkoutJourney = checkoutJourneyString
      ? JSON.parse(checkoutJourneyString)
      : { ...initialState.checkoutJourney };
    yield put(
      success(
        "CHECK_LOGIN_STATUS",
        false,
        [
          "userToken",
          "userDetails",
          "currentUsage",
          "currentSubscription",
          "checkoutJourney",
        ],
        {
          userToken,
          userDetails,
          currentUsage,
          currentSubscription,
          checkoutJourney,
        }
      )
    );
  } catch (error) {
    yield put(
      failure(
        "CHECK_LOGIN_STATUS",
        false,
        [
          "userToken",
          "userDetails",
          "currentUsage",
          "currentSubscription",
          "checkoutJourney",
        ],
        { ...initialState }
      )
    );
  }
}

function* logoutCall() {
  try {
    yield call(emptyStorage, "userToken");
    yield call(emptyStorage, "userDetails");
    yield call(emptyStorage, "currentUsage");
    yield call(emptyStorage, "currentSubscription");
    yield call(emptyStorage, "checkoutJourney");
    yield put(
      success(
        "LOGOUT",
        false,
        [
          "userToken",
          "userDetails",
          "currentUsage",
          "currentSubscription",
          "checkoutJourney",
        ],
        { ...initialState }
      )
    );
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("LOGOUT", error, ["userToken", "userDetails"], {}));
  }
}

function* loginApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const response = yield call(
      getApiCall,
      "login",
      "post",
      false,
      payload.login
    );
    const { data, success: apiSuccess, message } = response;
    if (data && apiSuccess) {
      const userToken = data.token;
      yield call(writeStorage, "userToken", JSON.stringify(userToken));
      yield put(
        success("LOGIN", true, ["userToken"], {
          userToken,
        })
      );
      yield put(getProfile());
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(
      failure(
        "LOGIN",
        error,
        [
          "userToken",
          "userDetails",
          "currentUsage",
          "currentSubscription",
          "checkoutJourney",
        ],
        { ...initialState }
      )
    );
  }
}

function* registerApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.register
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "register",
        "post",
        false,
        payload.register
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield put(
          success(
            "REGISTER",
            false,
            ["email"],
            { email: payload.register.email },
            "Registration Successfull, Please check your inbox for verification code!!!"
          )
        );
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("register", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("REGISTER", error));
  }
}

function* requestEmailVerify(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.requestEmailVerify
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "otpGenerate",
        "post",
        false,
        payload.requestEmailVerify
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield put(
          success(
            "REQUEST_EMAIL_VERIFY",
            false,
            ["email"],
            { email: payload.requestEmailVerify.email },
            "OTP Resend successfully, Please check your inbox for verification code!!!"
          )
        );
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("requestEmailVerify", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("REQUEST_EMAIL_VERIFY", error));
  }
}

function* verifyApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.verify
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(getApiCall, "otpVerify", "post", false, {
        ...payload.verify,
        otp: parseInt(payload.verify.otp, 10),
      });

      const { data, success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield call(
          getApiCall,
          "subscription/create-free-subscription",
          "post",
          data.token,
          { userId: data.userId }
        );
        yield put(success("VERIFY", false, [], {}, "Email Verified"));
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("verify", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("VERIFY", error));
  }
}

function* forgotPasswordApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.forgotPassword
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "forgotPassword",
        "post",
        false,
        payload.forgotPassword
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield put(
          success(
            "FORGOT_PASSWORD",
            false,
            ["email"],
            { email: payload.forgotPassword.email },
            "Email with OTP is sent, please use to Reset Password!!!"
          )
        );
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("forgotPassword", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("FORGOT_PASSWORD", error));
  }
}

function* resetPasswordApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.resetPassword
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "forgotUpdatePassword",
        "put",
        false,
        {
          ...payload.resetPassword,
          otp: parseInt(payload.resetPassword.otp, 10),
        }
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield put(
          success(
            "RESET_PASSWORD",
            false,
            [],
            {},
            "Password Reset done, Please Login!!!"
          )
        );
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("resetPassword", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("RESET_PASSWORD", error));
  }
}

function* changePasswordApiCall(action) {
  try {
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.changePassword
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "changePassword",
        "post",
        false,
        payload.changePassword
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        yield put(
          success(
            "CHANGE_PASSWORD",
            false,
            [],
            {},
            "Password Changed, Login Again!!!"
          )
        );
        yield put(logout());
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("changePassword", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("CHANGE_PASSWORD", error));
  }
}

function* getPlanApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const selectedPlan = yield select(makeSelectAppCurrentSubscription());
    const activePlanExist =
      selectedPlan &&
      selectedPlan[0] &&
      selectedPlan[0].isPlanActive &&
      selectedPlan[0].isPlanPaid &&
      selectedPlan[0].isTopUpAllowed;
    if (userToken && activePlanExist) {
      yield put(
        success("GET_PLAN", true, ["planList"], { planList: TOP_UP_PLANS })
      );
    } else {
      yield put(
        success("GET_PLAN", true, ["planList"], { planList: VALIDITY_PLANS })
      );
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_PLAN", error));
  }
}

function* addToCartCall(action) {
  try {
    const checkoutJourney = yield select(makeSelectAppCheckoutJourney());
    checkoutJourney.inProgress = true;
    checkoutJourney.name = action.payload.plan.name;
    checkoutJourney.isUnlimited = action.payload.plan.isUnlimited;
    checkoutJourney.planType = action.payload.plan.planType;
    checkoutJourney.duration = action.payload.plan.duration;
    checkoutJourney.planId = action.payload.plan.planId;

    const userDetailStringed = yield call(readStorage, "userDetails");
    const userDetails = userDetailStringed
      ? JSON.parse(userDetailStringed)
      : { ...initialState.userDetails };

    if (action.payload.plan.duration === "QUARTERLY") {
      checkoutJourney.price = action.payload.plan.monthlyPrice_EUR;
      checkoutJourney.totalMinutes = action.payload.plan.totalMinutes;
    } else if (action.payload.plan.duration === "HALFYEARLY") {
      checkoutJourney.price =action.payload.plan.quarterlyPrice_EUR;        
      checkoutJourney.totalMinutes = action.payload.plan.totalMinutes * 3;
    } else if (action.payload.plan.duration === "YEARLY") {
      checkoutJourney.price =action.payload.plan.yearlyPrice_EUR;
      checkoutJourney.totalMinutes = action.payload.plan.totalMinutes * 12;
    } else {
      checkoutJourney.price =action.payload.plan.price_EUR;
      checkoutJourney.totalMinutes = action.payload.plan.totalMinutes;
    }
    if (action.payload.plan.planType === "TOP-UP") {
      const currentSubscription = yield select(
        makeSelectAppCurrentSubscription()
      );
      checkoutJourney.subsId =
        currentSubscription && currentSubscription[0]
          ? currentSubscription[0].id
          : "";
    }
    yield call(
      writeStorage,
      "checkoutJourney",
      JSON.stringify(checkoutJourney)
    );
    yield put(
      success("ADD_TO_CART", true, ["checkoutJourney"], {
        checkoutJourney,
      })
    );
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("ADD_TO_CART", error));
  }
}

function* checkoutApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const checkoutJourney = yield select(makeSelectAppCheckoutJourney());
    const userDetails = yield select(makeSelectAppUserDetails());
    const currentSubscription = yield select(
      makeSelectAppCurrentSubscription()
    );
    const response = yield call(
      getApiCall,
      "subscription/create-trans",
      "post",
      userToken,
      {
        name: checkoutJourney.name,
        duration: checkoutJourney.duration || "NA",
        price: checkoutJourney.price,
        isUnlimited: checkoutJourney.isUnlimited || false,
        totalMinutes: checkoutJourney.totalMinutes,
        planType: checkoutJourney.planType,
        planId: checkoutJourney.planId,
        subsId:
          currentSubscription && currentSubscription[0]
            ? currentSubscription[0].id
            : "",
        userId: userDetails.id,
      }
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield call(
        writeStorage,
        "checkoutJourney",
        JSON.stringify({
          ...checkoutJourney,
          ourTransId: data.transId,
          subsId: data.subsId,
          paymentParterId: data.paymentParterId,
        })
      );
      window.open(data.stripeUrl, "_self");

      ////
      //            yield put(
      //                    success("PAYMENT_RESPONSE", true, ["checkoutJourney"], {
      //                        checkoutJourney: {
      //                            ...checkoutJourney,
      //                            ourTransId: data.transId,
      //                            subsId: data.subsId,
      //                            paymentParterId: data.paymentParterId,
      //                        },
      //                    })
      //                    );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("PAYMENT_RESPONSE", error));
  }
}

function* cancelTransCall() {
  try {
    const checkoutJourney = yield select(makeSelectAppCheckoutJourney());
    yield call(
      writeStorage,
      "checkoutJourney",
      JSON.stringify({
        ...checkoutJourney,
        ourTransId: "",
      })
    );
    yield put(
      success("CANCEL_TRANS", true, ["checkoutJourney"], {
        checkoutJourney: {
          ...checkoutJourney,
          ourTransId: "",
        },
      })
    );
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("CANCEL_TRANS", error));
  }
}

function* updatePaymentResponseApiCall(action) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const checkoutStatus = yield select(makeSelectAppCheckoutJourney());
    const { gatewayResponse } = action.payload;
    const response = yield call(
      getApiCall,
      "subscription/update-trans",
      "put",
      userToken,
      {
        razorpayPaymentId: gatewayResponse.razorpayPaymentId,
        price: checkoutStatus.price,
        transId: checkoutStatus.ourTransId,
        subsId: checkoutStatus.subsId,
        paymentParterId: checkoutStatus.paymentParterId,
        planType: checkoutStatus.planType,
        planMinutes: checkoutStatus.totalMinutes,
      }
    );

    const { success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield call(
        writeStorage,
        "checkoutJourney",
        JSON.stringify({
          ...checkoutStatus,
          status: "success",
        })
      );
      yield put(
        success("PAYMENT_RESPONSE", true, ["checkoutJourney"], {
          checkoutJourney: { ...checkoutStatus, status: "success" },
        })
      );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("PAYMENT_RESPONSE", error));
  }
}

function* getProfileApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(getApiCall, "users", "get", userToken, {});
    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield call(writeStorage, "userDetails", JSON.stringify(data));
      yield put(
        success("GET_PROFILE", true, ["userDetails"], {
          userDetails: data,
        })
      );

      yield put(getSubscription());

      yield put(getUsage());
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_PROFILE", error));
  }
}

function* updateProfileApiCall(action) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const payload = yield select(makeSelectAppFormValue());
    const formValid = validateFormData(
      action.payload.formFields,
      payload.updateProfile
    );
    if (formValid && typeof formValid === "boolean") {
      const response = yield call(
        getApiCall,
        "users",
        "put",
        userToken,
        payload.updateProfile
      );

      const { success: apiSuccess, message } = response;
      if (apiSuccess) {
        const userDetails = yield select(makeSelectAppUserDetails());
        yield call(
          writeStorage,
          "userDetails",
          JSON.stringify({
            ...userDetails,
            firstName: payload.updateProfile.firstName,
            lastName: payload.updateProfile.lastName,
          })
        );
        yield put(
          success(
            "UPDATE_PROFILE",
            false,
            ["userDetails"],
            {
              userDetails: {
                ...userDetails,
                firstName: payload.updateProfile.firstName,
                lastName: payload.updateProfile.lastName,
              },
            },
            "Profile Updated Successfull!!!"
          )
        );
      } else {
        const error = new Error(message);
        throw error;
      }
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failedValidation("updateProfile", error, formValid));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("UPDATE_PROFILE", error));
  }
}

function* deleteProfileApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const userDetails = yield select(makeSelectAppUserDetails());
    const response = yield call(getApiCall, "users", "delete", userToken, {
      user: userDetails,
    });

    const { success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success(
          "DELETE_PROFILE",
          false,
          [
            "userToken",
            "userDetails",
            "currentUsage",
            "currentSubscription",
            "checkoutJourney",
          ],
          { ...initialState },
          "User Deleted Successfully"
        )
      );
      yield put(logout());
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("DELETE_PROFILE", error));
  }
}

function* getTransactionsApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const userDetails = yield select(makeSelectAppUserDetails());
    const response = yield call(
      getApiCall,
      "subscription/get-past-transactions",
      "post",
      userToken,
      { userId: userDetails.id, limit: 100, page: 1 }
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success("GET_TRANSACTION", true, ["transactionHistory"], {
          transactionHistory: data?.list,
        })
      );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_TRANSACTION", error));
  }
}

function* getCurrentSubscriptionApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const userDetails = yield select(makeSelectAppUserDetails());
    const response = yield call(
      getApiCall,
      "subscription/get-current-subscription",
      "post",
      userToken,
      { userId: userDetails.id }
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success("GET_SUBSCRIPTION", true, ["currentSubscription"], {
          currentSubscription: data,
        })
      );
      yield call(writeStorage, "currentSubscription", JSON.stringify(data));
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_SUBSCRIPTION", error));
  }
}

function* getUsageApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const userDetails = yield select(makeSelectAppUserDetails());
    const currentSubscription = yield select(
      makeSelectAppCurrentSubscription()
    );
    const startDate =
      currentSubscription &&
      currentSubscription[0] &&
      currentSubscription[0].startDate
        ? currentSubscription[0].startDate.split("T")[0]
        : "";
    const response = yield call(
      getApiCall,
      `meetings/usage/${userDetails.id}?startDate=${startDate}`,
      "get",
      userToken,
      { startDate }
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess && data) {
      const currentUsage = data.length > 0 ? data[0].total_usage : 0;
      yield put(
        success("GET_USAGE", true, ["currentUsage"], {
          currentUsage: currentUsage,
        })
      );
      yield call(writeStorage, "currentUsage", JSON.stringify(currentUsage));
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_USAGE", error));
  }
}

function* getMyMeetingDetailsApiCall(params) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      `meetings/details/${params.id}`,
      "get",
      userToken,
      {}
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success("GET_MY_MEETING", true, ["myMeeting"], {
          myMeeting: data,
        })
      );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_MY_MEETINGS", error));
  }
}

function* getMyMeetingsApiCall(params) {
  const queries = params?.query;
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      `meetings?page=${queries?.page ? queries?.page : ""}&search=${
        queries?.search ? queries?.search : ""
      }`,
      "get",
      userToken,
      {}
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success("GET_MY_MEETINGS", true, ["totalPages"], {
          totalPages: data.totalPages,
        })
      );
      yield put(
        success("GET_MY_MEETINGS", true, ["myMeetings"], {
          myMeetings: data.list,
        })
      );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_MY_MEETINGS", error));
  }
}

function* updateMyMeeting(action) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      `meetings/details/${action.id}`,
      "put",
      userToken,
      action.payload
    );
    const { success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(success("UPDATE_MEETING"));
      yield call(getMyMeetingDetailsApiCall, action);
      yield call(getMyMeetingsApiCall);
      // window.location.reload();
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("UPDATE_MEETING", error));
  }
}

function* updateSpeaker(action) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      `meetings/speaker/update/${action.id}`,
      "post",
      userToken,
      action.payload
    );
    const { success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield call(getMyMeetingDetailsApiCall, action);
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("UPDATE_MEETING", error));
  }
}

function* geSharedMeetingApiCall() {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      "meetings/shared",
      "get",
      userToken,
      {}
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(
        success("GET_SHARED_MEETINGS", true, ["sharedMeetings"], {
          sharedMeetings: data.list,
        })
      );
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("GET_SHARED_MEETINGS", error));
  }
}

function* resetSingleMeeting(action) {
  try {
    const singleMeeting = initialState.myMeeting;
    yield call(writeStorage, "myMeeting", JSON.stringify(singleMeeting));
    yield put(
      success("GET_MY_MEETING", true, ["myMeeting"], {
        myMeeting: undefined,
      })
    );
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("RESET_FLOW", error));
  }
}
function* resetFormCall(action) {
  try {
    const checkoutJourney = initialState.checkoutJourney;
    yield call(
      writeStorage,
      "checkoutJourney",
      JSON.stringify(checkoutJourney)
    );
    yield put(success("RESET_FLOW", true, [], {}));
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("RESET_FLOW", error));
  }
}

function* uploadMeeting(action) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const userDetails = yield select(makeSelectAppUserDetails());

    const response = yield call(
      getApiCall,
      "meetings",
      "post",
      userToken,
      { ...action.payload.formFields, members: userDetails._id },
      "multipart/form-data"
    );

    const { success: apiSuccess, message } = response;
    if (apiSuccess) {
      yield put(success("UPLOAD_MEETING"));
      window.location.reload();
    } else {
      const error = {};
      error.message =
        "Form Validation Failed, please address this and try again!!!";
      yield put(failure("UPLOAD_MEETING", error));
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("UPLOAD_MEETING", error));
  }
}

function* getFileDownloadApiCall(params) {
  try {
    const userToken = yield select(makeSelectAppUserToken());
    const response = yield call(
      getApiCall,
      `meetings/export/${params.id}?type=pdf`,
      "get",
      userToken,
      {}
    );

    const { data, success: apiSuccess, message } = response;
    if (apiSuccess) {
      if (data.path) {
        window.open(data.path, "_blank");
      } else {
        yield put(
          success("DOWNLOAD_FILE", true, ["fileDetails"], {
            fileDetails: data,
          })
        );
      }
    } else {
      const error = new Error(message);
      throw error;
    }
  } catch (error) {
    error.message = error.message
      ? error.message
      : "Something went wrong, please contact admin if issue persists.";
    yield put(failure("DOWNLOAD_FILE", error));
  }
}

// Individual exports for testing
export default function* appSaga() {
  yield takeLatest(CHECK_LOGIN_STATUS, checkLoginStatusCall);
  yield takeLatest(LOGOUT, logoutCall);
  yield takeLatest(LOGIN, loginApiCall);
  yield takeLatest(REGISTER, registerApiCall);
  yield takeLatest(REQUEST_EMAIL_VERIFY, requestEmailVerify);
  yield takeLatest(VERIFY, verifyApiCall);
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordApiCall);
  yield takeLatest(RESET_PASSWORD, resetPasswordApiCall);
  yield takeLatest(CHANGE_PASSWORD, changePasswordApiCall);
  yield takeLatest(GET_PLAN, getPlanApiCall);
  yield takeLatest(ADD_TO_CART, addToCartCall);
  yield takeLatest(CHECKOUT, checkoutApiCall);
  yield takeLatest(CANCEL_TRANS, cancelTransCall);
  yield takeLatest(PAYMENT_RESPONSE, updatePaymentResponseApiCall);
  yield takeLatest(GET_PROFILE, getProfileApiCall);
  yield takeLatest(UPDATE_PROFILE, updateProfileApiCall);
  yield takeLatest(DELETE_PROFILE, deleteProfileApiCall);
  yield takeLatest(GET_TRANSACTION, getTransactionsApiCall);
  yield takeLatest(GET_SUBSCRIPTION, getCurrentSubscriptionApiCall);
  yield takeLatest(GET_USAGE, getUsageApiCall);
  yield takeLatest(UPDATE_MEETING, updateMyMeeting);
  yield takeLatest(GET_MY_MEETINGS, getMyMeetingsApiCall);
  yield takeLatest(GET_MY_MEETING, getMyMeetingDetailsApiCall);
  yield takeLatest(GET_SHARED_MEETINGS, geSharedMeetingApiCall);
  yield takeLatest(RESET_FLOW, resetFormCall);
  yield takeLatest(RESET_SINGLE_MEETING, resetSingleMeeting);
  yield takeLatest(UPLOAD_MEETING, uploadMeeting);
  yield takeLatest(DOWNLOAD_FILE, getFileDownloadApiCall);
  yield takeLatest(UPDATE_SPEAKER, updateSpeaker);
}
