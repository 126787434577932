import React from "react";
import { NavHashLink } from "react-router-hash-link";
        const HowItWorks = () => (
        <section className="how-it-works pt-3">
    <div className="container" data-aos="fade-up">
        <div className="content row">
            <div className="col-md-6 col-lg-8 d-flex justify-content-between flex-column">
                <div className="box">
                    <h2>                         
                        Sind Sie es leid, im Papierkram zu ertrinken? <br/>
                        <span className="mossai-text">Moss AI</span> ist hier, um Ihnen die Arbeit zu erleichtern. 
                    </h2>
                    <br/>
                    <h3>
                        Moss AI ist ein hochmodernes Sprachassistenzsystem für regionale Sprachen, das speziell für Anwälte entwickelt wurde. Nutzen Sie die Kraft der Spracherkennungstechnologie, um Ihre Dokumentationsaufgaben zu optimieren, sei es beim Verfassen von Briefen, bei der Beratung von Mandanten, bei Verhandlungen über Vergleiche, bei der Verteidigung oder Verfolgung von Fällen, bei der Überwachung von Rechtsanwaltsfachangestellten oder bei der Durchführung von rechtlichen Recherchen.<br/><br/>
                        Unsere fortschrittliche Technologie rationalisiert den Prozess, sodass Sie sich auf das Wesentliche konzentrieren können – die Erbringung außergewöhnlicher Arbeit für Ihre Kunden.<br/>
                    </h3>
                    <br/>
                </div>
                <div>
                    <NavHashLink className="btn btn-primary" to="/home#contact">
                        Request a quote
                    </NavHashLink>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
                <ul className="mb-0">
                    <li>
                        <i className="bi bi-check-circle"></i>Ambient-Transkriptionen
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Nahtlose Diarisierung
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Regionale Sprachunterstützung
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Benutzerfreundliches Bedienfeld
                    </li>
                    <li>
                        <i className="bi bi-check-circle"></i>Vielfältige Einsatzmöglichkeiten
                    </li>                    
                </ul>
            </div>
        </div>
    </div>    
</section>
        );
export default HowItWorks;
