import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Pagination,
  Placeholder,
  Row,
} from "react-bootstrap";
import { cleanUp, timeToText, dateFormatter } from "../../utils/common";
import Avatar from "react-avatar";
import "./meetings.css";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getMyMeeting,
  resetMeeting,
  getDownloadFile,
  updateMeeting,
  updateSpeaker,
  getMyMeetings,
} from "../../containers/App/actions";
import {
  makeSelectAppMyMeeting,
  makeSelectAppMyMeetings,
  makeSelectAppMyMeetingsTotalPages,
} from "../../containers/App/selectors";
import { createStructuredSelector } from "reselect";
import { ToastContainer, toast } from "react-toastify";

const MeetingsDetail = ({
  title,
  myMeetings,
  totalPages,
  dGetMyMeetings,
  singleMeeting,
  dGetMyMeeting,
  dResetSingleMeeting,
  dGetDownloadFile,
  dUpdateMeeting,
  dUpdateSpeaker,
}) => {
  const [isEditMeetingName, setIsEditMeetingName] = useState({
    meetId: null,
    isEdit: false,
  });
  const [isEditSummary, setIsEditSummary] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [isSpeakerName, setIsSpeakerName] = useState({
    seq: null,
    edit: false,
  });
  const [isTranscript, setIsTranscript] = useState({
    seq: null,
    edit: false,
  });
  const [currentMeetingId, setCurrentMeetingId] = useState();
  const [speakerDetails, setSpeakerDetails] = useState({
    old_name: "",
    new_name: "",
  });
  const [meetingDetails, setMeetingDetails] = useState({});
  useEffect(() => {
    if (currentPage && searchString) {
      dGetMyMeetings({ page: currentPage, search: searchString });
    }
  }, [currentPage, dGetMyMeetings, searchString]);

  const nextPage = () => {
    if (currentPage > 0 && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1 && currentPage <= totalPages) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const handleAccordion = useCallback(
    async (id) => {
      try {
        await dResetSingleMeeting();
        if (id) {
          await dGetMyMeeting(id);
          setCurrentMeetingId(id);
        }
      } catch (err) {
        toast.error(err.message);
      }
    },
    [dGetMyMeeting, dResetSingleMeeting]
  );
  const handleFile = useCallback(
    async (id, language) => {
      try {
        if (id) {
          await dGetDownloadFile(id, language);
        }
      } catch (err) {
        toast.error(err.message);
      }
    },
    [dGetDownloadFile]
  );
  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };
  const handleMeetingEdit = async (e) => {
    e.stopPropagation();
    try {
      await dUpdateMeeting(currentMeetingId, meetingDetails);
      setIsEditMeetingName(false);
      setIsEditSummary(false);
      setIsTranscript((prev) => ({ ...prev, edit: false }));
      toast.success("Meeting details edited successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleUpdateSpeaker = async (id) => {
    try {
      await dUpdateSpeaker(id, speakerDetails);
      setIsSpeakerName((prev) => ({ ...prev, edit: false }));
      toast.success("Speaker name changed successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleMeetingTitle = (e) => {
    e.stopPropagation();
    setMeetingDetails({ ...meetingDetails, title: e.target.value });
  };
  const uniqueSpeakerIds = [
    ...new Map(
      singleMeeting?.transcript.map((s) => [
        s.speaker_id,
        { speaker_id: s.speaker_id, seq: s.seq },
      ])
    ).values(),
  ];
  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    if (currentPage && searchString) {
      await dGetMyMeetings({ page: currentPage, search: searchString });
    }
  };
  const handleSpeakerChange = (oldName, e) => {
    setSpeakerDetails({
      ...speakerDetails,
      old_name: oldName,
      new_name: e.target.value,
    });
  };
  const handleSummaryChange = (e) => {
    setMeetingDetails({ ...meetingDetails, summary: e.target.value });
  };
  const handleTranscriptChange = (index, transcript, e) => {
    const duplicateTranscript = [...transcript];
    const editedTranscript = {
      ...transcript[index],
      transcript: e.target.value,
    };
    duplicateTranscript[index] = editedTranscript;
    setMeetingDetails({ ...meetingDetails, transcript: duplicateTranscript });
  };
  return (
    <div className="m-t-10">
      <h2 className="m-b-20">
        {title} <span className="mossai-text">Meeting(s)</span>
      </h2>
      <Form onSubmit={(e) => handleSubmitSearch(e)}>
        <Row>
          <Col md="4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                onChange={handleSearch}
                placeholder="Meeting name search"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <ToastContainer autoClose={5000} />

      <Accordion className="mb-3">
        {myMeetings.map((myMeeting, index) => (
          <Accordion.Item
            eventKey={index}
            className="rounded-1"
            style={{ backgroundColor: "#f9f8f8" }}
          >
            <Accordion.Header onClick={() => handleAccordion(myMeeting.id)}>
              <div className="row w-100">
                <div className="d-flex  align-items-center col col-sm-3">
                  <div className="col col-sm-auto">
                    <Avatar
                      name={myMeeting.title}
                      round
                      size="50"
                      color="#6750A4"
                    />
                  </div>

                  {isEditMeetingName.isEdit &&
                  myMeeting.id === isEditMeetingName.meetId ? (
                    <FloatingLabel label="Meeting name">
                      <Form.Control
                        size="sm"
                        onChange={(e) => handleMeetingTitle(e)}
                        onClick={(e) => e.stopPropagation()}
                        defaultValue={myMeeting?.title ? myMeeting?.title : ""}
                        type="text"
                        placeholder="Enter meeting name"
                      />
                    </FloatingLabel>
                  ) : (
                    <p className="fw-bolder ms-2">{cleanUp(myMeeting.title)}</p>
                  )}
                  <Row>
                    <Col>
                      <Col className="p-l-3">
                        <i
                          className="bx bxs-edit-alt"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditMeetingName((prev) => ({
                              meetId: myMeeting.id,
                              isEdit: !prev.isEdit,
                            }));
                            setCurrentMeetingId(myMeeting.id);
                          }}
                        />
                      </Col>
                      {isEditMeetingName.isEdit &&
                        myMeeting.id === isEditMeetingName.meetId && (
                          <Col className="p-l-3">
                            <i
                              className="bi bi-check-circle text-success"
                              onClick={(e) => handleMeetingEdit(e)}
                            />
                          </Col>
                        )}
                    </Col>
                  </Row>
                </div>
                <div className="col col-sm-3">
                  <p>
                    <strong>Client name: </strong>
                    {cleanUp(myMeeting.patientName)}
                  </p>
                  <p>
                    <strong>Client id: </strong>
                    {cleanUp(myMeeting.patientId)}
                  </p>
                </div>

                <div className="col col-sm-3">
                  <p>
                    <i className="bx bx-calendar" />
                    {dateFormatter(myMeeting.meetingDate)}
                  </p>
                  <p>
                    <i className="bx bx-file" />
                    {myMeeting.language}
                  </p>
                </div>
                <div className="d-flex align-items-center col col-sm-3">
                  <div className="col col-sm-8">
                    <div className="mb-1">
                      <small
                        style={{ fontSize: "x-small" }}
                        className={`rounded-pill text-white p-t-5 p-b-5 p-l-10 m-r-10 p-r-10 ${
                          myMeeting.transcriptStatus === "completed"
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {cleanUp(myMeeting.transcriptStatus)}
                      </small>
                    </div>
                    <p class="mt-3">
                      <i className="bx bx-stopwatch" />
                      {timeToText(myMeeting.duration)}
                    </p>
                  </div>
                  {/*<div className="col col-sm-4" style={{ textAlign: "center" }}>
                    <a
                      onClick={() =>
                        handleFile(myMeeting.id, myMeeting.language)
                      }
                      style={{ fontSize: "xx-large" }}
                    >
                      <i class="bx bxs-file-pdf"></i>
                    </a>
                  </div>*/}
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {!singleMeeting ? (
                <>
                  {Array.from({ length: 5 }, () => (
                    <Placeholder as="p" animation="glow">
                      <Placeholder
                        style={{ backgroundColor: "#6750A4" }}
                        xs={12}
                      />
                    </Placeholder>
                  ))}
                </>
              ) : (
                <div>
                  {singleMeeting && singleMeeting?.audioFile?.length && (
                    <Fragment>
                      <Card.Text className="m-t-10">
                        <i className="bx bxl-audible" /> <b>Audio file</b>
                      </Card.Text>
                      <Card.Text className="w-full m-t-10">
                        <audio
                          className="w-full"
                          src={singleMeeting?.audioFile}
                          controls
                        />
                      </Card.Text>
                    </Fragment>
                  )}
                  {singleMeeting && singleMeeting?.summary && (
                    <div className="m-b-10">
                      <div className="d-inline">
                        <p className="meeting-details-heading d-inline-block">
                          Summary
                        </p>
                        <i
                            className="bx bxs-copy-alt p-l-5"
                            style={{ color: "#6750A4", cursor: "pointer" }}
                            onClick={() =>
                              copyClipboard(
                                singleMeeting?.summary                                  
                              )
                            }
                          />
                        <i
                          className="bx bxs-edit-alt p-l-5"
                          onClick={() => setIsEditSummary((prev) => !prev)}
                          style={{ color: "#6750A4", cursor: "pointer" }}
                        />
                        {isEditSummary && (
                          <i
                            className="bi bi-check-circle text-success p-l-8"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleMeetingEdit(e)}
                          />
                        )}
                      </div>
                      {!isEditSummary ? (
                        singleMeeting?.summary
                          .split("\n")
                          .map((line, index) => <p key={index}>{line}</p>)
                      ) : (
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={8}
                            defaultValue={singleMeeting?.summary}
                            onChange={(e) => handleSummaryChange(e)}
                          />
                        </Form.Group>
                      )}
                      {/* <Card.Text>
                        <ClinicalNotes notes={singleMeeting?.summary} />
                      </Card.Text> */}
                    </div>
                  )}
                  {/* {singleMeeting && (
                    <div className="m-b-10" style={{ minHeight: "50px" }}>
                      <p className="meeting-details-heading">Medications</p>
                    </div>
                  )} */}
                  {singleMeeting && singleMeeting?.transcript.length && (
                    <div className="m-b-10">
                      <div>
                        <div className="d-inline">
                          <p className="meeting-details-heading d-inline-block">
                            Transcript
                          </p>
                          <i
                            className="bx bxs-copy-alt p-l-5"
                            style={{ color: "#6750A4", cursor: "pointer" }}
                            onClick={() =>
                              copyClipboard(
                                singleMeeting?.transcript
                                  .map(
                                    (t) =>
                                      `${
                                        t?.speaker_id ? t?.speaker_id : ""
                                      } - ${t?.transcript} \n`
                                  )
                                  .join(" ")
                              )
                            }
                          />
                        </div>
                        <div className="mb-3">
                          {uniqueSpeakerIds.map((speakerId, index) => (
                            <div>
                              {isSpeakerName.edit &&
                              speakerId.seq === isSpeakerName.seq ? (
                                <InputGroup size="sm" className="w-25">
                                  <Form.Control
                                    aria-label="Small"
                                    onChange={(e) =>
                                      handleSpeakerChange(
                                        speakerId.speaker_id,
                                        e
                                      )
                                    }
                                    aria-describedby="inputGroup-sizing-sm"
                                    defaultValue={speakerId.speaker_id}
                                  />
                                </InputGroup>
                              ) : (
                                <p className="d-inline" key={index}>
                                  {speakerId.speaker_id}
                                </p>
                              )}
                              <Col className="p-l-3 d-inline">
                                {speakerId?.speaker_id && (
                                  <i
                                    className="bx bxs-edit-alt p-l-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setIsSpeakerName((prev) => {
                                        return {
                                          ...prev,
                                          seq: speakerId.seq,
                                          edit: !prev.edit,
                                        };
                                      })
                                    }
                                  />
                                )}
                                {isSpeakerName.edit &&
                                  speakerId.seq === isSpeakerName.seq && (
                                    <i
                                      className="bi bi-check-circle text-success p-l-8"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleUpdateSpeaker(myMeeting.id)
                                      }
                                    />
                                  )}
                              </Col>
                            </div>
                          ))}
                        </div>
                      </div>
                      {singleMeeting?.transcript.map((t, i) => (
                        <Row key={i}>
                          {t.speaker_id && (
                            <div className="d-flex">
                              <div className="circle m-t-5 m-r-5"></div>
                              <p className="fw-thinner d-inline">
                                {t.speaker_id}
                              </p>
                            </div>
                          )}
                          <div className="m-l-20 m-b-10">
                            {isTranscript.edit && isTranscript.seq === t.seq ? (
                              <InputGroup size="sm">
                                <Form.Control
                                  aria-label="Small"
                                  onChange={(e) =>
                                    handleTranscriptChange(
                                      i,
                                      singleMeeting?.transcript,
                                      e
                                    )
                                  }
                                  aria-describedby="inputGroup-sizing-sm"
                                  defaultValue={t.transcript}
                                />
                              </InputGroup>
                            ) : (
                              <Card.Text className="text-black d-inline">
                                {t.transcript}
                              </Card.Text>
                            )}
                            <div className="p-l-3 d-inline">
                              <i
                                className="bx bxs-edit-alt p-l-3"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setIsTranscript((prev) => {
                                    return {
                                      ...prev,
                                      seq: t.seq,
                                      edit: !prev.edit,
                                    };
                                  })
                                }
                              />
                              {isTranscript.edit &&
                                t.seq === isTranscript.seq && (
                                  <i
                                    className="bi bi-check-circle text-success p-l-8"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => handleMeetingEdit(e)}
                                  />
                                )}
                            </div>
                          </div>
                        </Row>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <Row>
        <Col md={{ span: 10, offset: 10 }}>
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} />
            <Pagination.Prev onClick={() => prevPage()} />
            <Pagination.Next onClick={() => nextPage()} />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
          </Pagination>
        </Col>
      </Row>
      {/* <Modal show={openEditClinicalNotes} onHide={handleEditClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-item-center">
            <i className="bx bx-mobile fs-30" />
          </div>
          <div className="d-flex justify-content-center new-meet-modal-title">
            Edit clinical notes
          </div>
          <EditClinicalNotes
            notes={singleMeeting?.summary}
            onSubmit={handleMeetingEdit}
            setMeetingData={setMeetingDetails}
            meetingDate={meetingDetails}
            onClose={handleEditClose}
          />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  singleMeeting: makeSelectAppMyMeeting(),
  myMeetings: makeSelectAppMyMeetings(),
  totalPages: makeSelectAppMyMeetingsTotalPages(),
});
function mapDispatchToProps(dispatch) {
  return {
    dGetMyMeeting: (id) => dispatch(getMyMeeting(id)),
    dResetSingleMeeting: () => dispatch(resetMeeting()),
    dGetDownloadFile: (id, lang) => dispatch(getDownloadFile(id, lang)),
    dUpdateMeeting: (id, payload) => dispatch(updateMeeting(id, payload)),
    dUpdateSpeaker: (id, payload) => dispatch(updateSpeaker(id, payload)),
    dGetMyMeetings: (queryString) => dispatch(getMyMeetings(queryString)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(MeetingsDetail);
