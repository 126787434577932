import React from "react";
        const Features = () => (
        <section id="features" className="features">
    <div className="container">
        <div className="section-title" data-aos="fade-up">
            <h2>Features</h2>
            <h3>Nahtlose Sprach-zu-Text-Integration</h3>
            <p>
                Verbessern Sie Ihren Dokumentationsprozess mit Moss AI.
            </p>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Unterstützung regionaler Sprachen*</h3>
            <p className="row language-tiles">
                <strong className="col">
                    <span>
                        Apple
                    </span>
                    English
                </strong>
                <strong className="col">
                    <span>
                        Apfel
                    </span>
                    German
                </strong>
                <strong className="col">
                    <span>
                        Manzana
                    </span>
                    Spanish
                </strong>                            

            </p>
            <small>*Transcript quality depends on audio quality</small>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Genauigkeit und Effizienz</h3>
            <p>
                Erleben Sie unvergleichliche Genauigkeit und Effizienz bei der Transkription Ihrer gesprochenen Worte in Text.
            </p>
        </div>
        <div className="section-title" data-aos="fade-up">
            <h3>Vielseitige Anwendungen</h3>
            <p>
                Perfekt für das Verfassen von Briefen, die Beratung von Mandanten, die Aushandlung von Vergleichen und mehr.
            </p>
        </div>        
        <div className="section-title" data-aos="fade-up">
            <h3>Benutzerfreundliche Oberfläche</h3>
            <p>
                <span className="mossai-text">Moss AI</span> ist einfach zu bedienen und lässt sich mühelos in Ihren täglichen Arbeitsablauf integrieren.
            </p>
        </div>        
    </div>
</section>
        );
export default Features;
