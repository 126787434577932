import axios from "axios";

import { myEndPoint } from "../config/_env";

/**
 * Returns validated and parsed API Response
 *
 * @param {Object} response  API Response of Axios Call
 *
 * @returns Either formatted JSON data or Error
 */
function parseResponse(response) {
  let text = "";
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    text = response.data || "Server Error!";
  } else {
    text = "Server Error";
  }

  const error = new Error(text.message);
  error.response = response;
  throw error;
}

/**
 * Returns Auth header for API call
 *
 * @param {String} userToken
 *
 * @returns Complete Header
 */
const getHeader = (userToken) => {
  const header = { "Content-Type": "application/json" };
  if (userToken) {
    header.Authorization = userToken;
  }
  return header;
};
/**
 * Call RESTful API call to all four endpoint
 *
 * @param {String} url,
 * @param {String} type,    Possible values are 'get, post, update, delete, put'
 * @param {String} userToken, Possible values are 'userToken'
 * @param {Object} [body] Params passed for API call, not required in get call
 *
 * @returns Parsed API response or Error
 */
export const getApiCall = async (url, type, userToken, body, contentType) => {
  try {
    const fullUrl = `${myEndPoint}/${url}`;
    const header = getHeader(userToken);
    // const data = new FormData();
    // data.append("", JSON.stringify(body));
    var formData = JSON.stringify(body);
    if (contentType === 'multipart/form-data') {
      header['Content-Type'] = contentType;
      formData = new FormData();
      Object.keys(body).forEach((key) => {
        formData.append(key, body[key]);
      });
    }
  
    const response = await axios({
      method: type,
      url: fullUrl,
      data: formData,
      headers: header,
    });
    return parseResponse(response);
  } catch (err) {
    return parseResponse(err.response);
  }
};
