import React from "react";
import FormElement from "../FormElement";

const FormComponent = ({
  formFields,
  formValue,
  formValid,
  handleInputChange,
  submitLabel,
  handleSubmit,
}) => (
  <>
    {formFields.map((formFiled) => (
      <FormElement
        formFiled={formFiled}
        fieldValue={formValue[formFiled.name]}
        fieldValid={formValid[formFiled.name]}
        handleChange={(value) => handleInputChange(value, formFiled.name)}
        key={formFiled.name}
      />
    ))}
    <div className="text-right p-t-8 p-b-31"></div>

    <div className="container-login100-form-btn">
      <div className="wrap-login100-form-btn">
        <div className="login100-form-bgbtn"></div>
        <button className="login100-form-btn" onClick={handleSubmit}>
          {submitLabel}
        </button>
      </div>
    </div>
  </>
);

export default FormComponent;
