import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { COUNTRY } from "../../containers/App/internalConfig";

const FormElement = ({ formFiled, fieldValue, fieldValid, handleChange }) => {
  const [showText, setShowText] = useState(false);
  return (
    <>
      <div className="wrap-input100 validate-input m-b-23">
        <span className="label-input100">
          {formFiled.label}{" "}
          {formFiled.validations.map(
            (validation) =>
              fieldValid &&
              fieldValid[validation.name] && (
                <span className="invalid">, {validation.message}</span>
              )
          )}
        </span>


        {formFiled.type === "select" &&(
                <select name={formFiled.name} onChange={(e) => handleChange(e.target.value)} className="input100" placeholder={formFiled.placeholder} autoComplete="off" style={{border: 'none'}}>
    <option value="">Choose Country</option>        
    {COUNTRY.map((country) => (
                     <option value={country.abbreviation}>{country.country}</option>
          ))}
            
            </select>     
            )}
    {formFiled.type !== "select" &&(
            
            
        <input
          className="input100"
          name={formFiled.name}
          type={
            formFiled.type === "password" && showText ? "text" : formFiled.type
          }
          placeholder={formFiled.placeholder}
          value={fieldValue}
          onChange={(e) => handleChange(e.target.value)}
          readOnly={formFiled.readOnly}
          autoComplete="off"
        />
        )}
        {formFiled.type === "password" && showText && (
          <button
            className="show-password-icon pull-right"
            onClick={() => setShowText(false)}
            data-symbol="&#xf15b;"
          />
        )}
        {formFiled.type === "password" && !showText && (
          <button
            className="show-password-icon pull-right"
            onClick={() => {
              setShowText(true);
            }}
            data-symbol="&#xf15c;"
          />
        )}
        {formFiled.type === "password" && (
          <span className="focus-input100" data-symbol="&#xf190;" />
        )}
        {formFiled.name === "email" && (
          <span className="focus-input100" data-symbol="&#9993;" />
        )}
        {formFiled.name === "firstName" && (
          <span className="focus-input100" data-symbol="&#xf206;"></span>
        )}
        {formFiled.name === "lastName" && (
          <span className="focus-input100" data-symbol="&#xf206;"></span>
        )}
      </div>
      {formFiled.label === "Password" && (
        <div className="text-right p-t-8 p-b-31">
          <NavLink to="/forgot-password">Forgot password?</NavLink>
        </div>
      )}
    </>
  );
};

export default FormElement;
