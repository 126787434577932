import React from "react";

const Gallery = () => (
  <>
    {/* <section id="gallery" className="gallery">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Gallery</h2>
    </div>
  </div>

  <div className="container" data-aos="fade-up">
    <div className="gallery-slider swiper">
      <div className="swiper-wrapper">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={35}
          breakpoints={{
            // when window width is >= 200px
            200: {
              width: 200,
              slidesPerView: 1,
            },
            // when window width is >= 375px
            375: {
              width: 375,
              slidesPerView: 1,
            },
            // when window width is >= 425px
            425: {
              width: 425,
              slidesPerView: 2,
            },
            //when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 3,
            },
          }}
          //slidesPerView={7}
          autoPlay={{ delay: 1000 }}
          loop={true}
        >
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-1.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-1.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-2.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-2.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-3.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-3.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-4.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-4.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-5.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-5.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-6.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-6.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-7.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-7.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-8.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-8.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-9.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-9.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-10.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-10.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-11.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-11.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="swiper-slide">
              <a
                href="assets/img/gallery/gallery-12.png"
                className="gallery-lightbox"
                data-gall="gallery-carousel"
              >
                <img
                  src="assets/img/gallery/gallery-12.png"
                  className="img-fluid"
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="swiper-pagination"></div>
    </div>
  </div>
</section> */}
  </>
);

export default Gallery;
