import React, { useState } from "react";
import PlanList from "../PlanList";
import Popup from "../Popup";
const Plans = ({
plansAvailable,
        addToCart,
        isLogin,
        moveToPage,
        isPlanActive,
        userDetails
        }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(false);
    return (
            <section id="plans" className="pricing">
                <Popup
                    isOpen={showModal}
                    onSubmit={addToCart}
                    plan={currentPlan}
                    closeModal={() => setShowModal(false)}
                    moveToPage={moveToPage}
                    isLogin={isLogin}
                    userDetails={userDetails}
                    />
                <div className="container">
                    <div className="section-title">
                        <h2>Pricing and Plans</h2>
                        <h3>Your first month is on us!</h3>
                        <p>
                            If you want to create transcripts and you're looking for a truly
                            innovative and game-changing product, we encourage you to give{" "}
                            <span className="mossai-text">Moss.ai</span> a try today. Or talk to
                            our sales team for more information.
                        </p>
                    </div>
            
                    <div className="row no-gutters">
                        <ul class="nav nav-pills justify-content-end mb-5">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" aria-current="page" href="#annual">Annual</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#quaterly">Half Yearly</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#monthly">Quarterly</a>
                            </li>
                        </ul>
                        <div class="tab-content clearfix">
                            <div class="tab-pane active" id="annual">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="annual"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                            <div class="tab-pane" id="quaterly">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="quaterly"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                            <div class="tab-pane" id="monthly">
                                {plansAvailable.map((plan) => (
                                <PlanList
                        price="monthly"
                                    key={plan.name}
                                    userDetails={userDetails}
                                    plan={plan}
                                    isLogin={isLogin}
                                    isPlanActive={isPlanActive}
                                    addToCart={
                                                            plan.planType === "TOP-UP"
                                                                    ? () => {
                                                                addToCart(plan);
                                                                moveToPage("/cart");
                                                            }
                                                            : () => {
                                                                setCurrentPlan(plan);
                                                                setShowModal(true);
                                                            }
                                    }
                                    />
                                                ))}
                            </div>
                        </div>                                            
                    </div>
                </div>
            </section>
            );
};
export default Plans;
