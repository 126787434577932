export const formFields = [
  {
    readOnly: true,
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter Registered Email",
    validations: [
      { name: "isRequired", message: " is Mandatory" },
      { name: "isEmail", message: " is Valid Email" },
    ],
    fieldName: "email",
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
  },
  {
    readOnly: false,
    label: "OTP",
    name: "otp",
    type: "password",
    placeholder: "Enter OTP Received on your Registered Email",
    validations: [{ name: "isRequired", message: " is Mandatory" }],
    fieldName: "otp",
    validationRules: {
      isRequired: true,
    },
  },
];
