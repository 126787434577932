import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, requestEmailVerify, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const RequestEmailVerify = ({
  isLogin,
  dRequestEmailVerify,
  dInputChange,
  formValue,
  formValid,
  formComplete,
  isPageReady,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);
  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }
  if (isPageReady && formComplete.REQUEST_EMAIL_VERIFY) {
    return <Redirect to="/verify" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Verify Email</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.requestEmailVerify}
            formValid={formValid.requestEmailVerify}
            handleInputChange={dInputChange}
            submitLabel="Submit"
            handleSubmit={dRequestEmailVerify}
          />
        </div>
        <div className="link-block">
          <NavLink to="/register">
            <strong>Not yet registered?</strong>
            <span className="mossai-text"> Sign up now</span>
          </NavLink>
          or
          <NavLink to="/login">
            <strong>Already Verified?</strong>
            <span className="mossai-text"> Login</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) =>
      dispatch(inputChange("requestEmailVerify", fieldName, value)),
    dRequestEmailVerify: () => {
      dispatch(requestEmailVerify(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(RequestEmailVerify);
