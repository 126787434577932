import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { makeSelectAppFormValue } from "../App/selectors";
import { inputChange, login, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const Login = ({
  isLogin,
  dLogin,
  dInputChange,
  formValue,
  isPageReady,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);
  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }
  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Login</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.login}
            formValid={{}}
            handleInputChange={dInputChange}
            submitLabel="Login"
            handleSubmit={dLogin}
          />
        </div>
        <div className="link-block">
          <NavLink to="/register">
            <strong>New to Moss.ai?</strong>
            <span className="mossai-text"> Sign up now</span>
          </NavLink>
          or
          <NavLink to="/request-email-verify">
            <strong>Email Not Verified?</strong>
            <span className="mossai-text"> Verify Now</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) =>
      dispatch(inputChange("login", fieldName, value)),
    dLogin: () => {
      dispatch(login());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Login);
