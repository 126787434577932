import React from "react";
        const WhyMossAI = () => (
        <section id="why-moss-ai" className="details">
    <div className="container features">
        <div className="section-title">
            <h2>
                Warum <span className="mossai-text">Moss AI</span> wählen?
            </h2>
        </div>
        <div className="row content">
            <div
                className="content d-flex flex-column justify-content-center p-0"
                data-aos="fade-up"
                >
                <div className="row">
                    <div className="section-title" data-aos="fade-up">
                        <h3>Zugeschnitten auf juristische Fachleute</h3>
                        <p>
                            Speziell entwickelt, um den einzigartigen Bedürfnissen von Anwälten und juristischen Fachleuten gerecht zu werden.
                        </p>
                    </div>
                    <div className="section-title" data-aos="fade-up">
                        <h3>Spitzentechnologie</h3>
                        <p>
                            Wir nutzen die neueste Sprach-zu-Text-Technologie, um Präzision und Zuverlässigkeit zu gewährleisten.
                        </p>
                    </div>
                    <div className="section-title" data-aos="fade-up">
                        <h3> Expertise in regionalen Sprachen </h3>
                        <p>
                            Unübertroffene Unterstützung für regionale Sprachen, damit keine Details in der Übersetzung verloren gehen.
                        </p>
                    </div>
                    <div className="section-title" data-aos="fade-up">
                        <h3> Produktivität steigern</h3>
                        <p>
                            Automatisieren Sie Ihren Dokumentationsprozess und gewinnen Sie wertvolle Zeit, um sich auf das Wesentliche zu konzentrieren.
                        </p>
                    </div>                                       
                </div>
            </div>
        </div>
    </div>
</section>
        );
export default WhyMossAI;
