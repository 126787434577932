export const formFields = [
  {
    readOnly: false,
    label: "First Name",
    name: "firstName",
    type: "text",
    placeholder: "Enter your First Name",
    validations: [{ name: "isRequired", message: " is Mandatory" }],
    fieldName: "firstName",
    validationRules: {
      isRequired: true,
    },
  },
  {
    readOnly: false,
    label: "Last Name",
    name: "lastName",
    type: "text",
    placeholder: "Enter your Last Name",
    validations: [],
    fieldName: "lastName",
    validationRules: {},
  },
];
