import React, { useEffect } from "react";
import CookiePolicyContent from "../../components/CookiePolicyContent";
const CookiePolicy = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  return (
    <div className="container">
      <div className="row content m-t-100 p-t-20 p-b-20">
        <div className="col-12">
          <CookiePolicyContent />
        </div>
      </div>
    </div>
  );
};
export default CookiePolicy;
