import React from "react";
import { Card } from "react-bootstrap";
import {
  dateFormatter,
  moneyFormatter,
  cleanUp,
  timeToText,
} from "../../utils/common";

const SubscriptionDetail = ({ subscription, currentUsage }) => {
  return (
    <div className="m-t-30">
      <h2 className="m-b-20">
        My <span className="mossai-text">Subscription</span>
      </h2>
      <Card className="profile-card">
        <Card.Header>
          <Card.Title>
            <h3>
              {cleanUp(subscription.name)}
              <small
                className={`rounded text-white m-l-20 p-t-5 p-b-5 p-l-10 m-r-10 p-r-10 pull-right ${
                  subscription.isPlanActive ? "bg-success" : "bg-danger"
                }`}
              >
                {subscription.isPlanActive ? "Active" : "Expired"}
              </small>
            </h3>
          </Card.Title>
        </Card.Header>
        <Card.Body className="row justify-content-between">
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-money" />
            Cost: {moneyFormatter(subscription.price)}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-pie-chart" />
            Current Usage:{" "}
            {currentUsage ? timeToText(currentUsage) : "Not Used"}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bxs-circle" />
            Total Quota:{" "}
            {!!subscription.isUnlimited
              ? "Unlimited"
              : cleanUp(subscription.totalMinutes)}{" "}
            Mins
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-calendar-check" />
            Purchased On: {dateFormatter(subscription.startDate)}
          </Card.Text>
          <Card.Text className="col-auto m-t-10">
            <i className="bx bx-calendar-x" />
            Expires On: {dateFormatter(subscription.endDate)}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SubscriptionDetail;
