import React from "react";

const LoadingIndicator = ({ isLoading }) => (
  <>
    {isLoading && (
      <div className="Toastify">
        <div className="Toastify__toast-container Toastify__toast-container--top-right">
          <div
            className="Toastify__toast Toastify__toast-theme--light Toastify__toast--success Toastify__toast--close-on-click"
            // style="--nth: 1; --len: 1;"
          >
            <div role="alert" className="Toastify__toast-body">
              <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                <svg
                  viewBox="0 0 24 24"
                  width="100%"
                  height="100%"
                  fill="var(--toastify-icon-color-success)"
                >
                  <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
                </svg>
              </div>
              <div>Loading...</div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

export default LoadingIndicator;
