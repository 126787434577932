/*
 *
 * App reducer
 *
 */
import produce from "immer";
import { toast } from "react-toastify";
import { initialState } from "./initialState";
import { cleanUp } from "../../utils/common";

import {
  INPUT_CHANGE,
  SUCCESS,
  FAILURE,
  FAILED_VALIDATION,
  CHECK_LOGIN_STATUS,
  LOGOUT,
  LOGIN,
  REGISTER,
  REQUEST_EMAIL_VERIFY,
  VERIFY,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_PLAN,
  ADD_TO_CART,
  CHECKOUT,
  CANCEL_TRANS,
  PAYMENT_RESPONSE,
  GET_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  GET_TRANSACTION,
  GET_SUBSCRIPTION,
  GET_USAGE,
  GET_MY_MEETINGS,
  GET_SHARED_MEETINGS,
  RESET_FLOW,
  DOWNLOAD_FILE,
} from "./constants";

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    /**
     * Variables for use
     */
    switch (action.type) {
      case INPUT_CHANGE:
        draft.formsValue[action.payload.formName][action.payload.fieldName] =
          action.payload.value;
        break;
      case SUCCESS:
        if (action.payload.actionName === 'UPLOAD_MEETING') {
          toast.success('Meeting uploaded successfully', {
            onClose: toast.dismiss(),
          });
        }
        if (action.payload.actionName === 'CHECK_LOGIN_STATUS') {
          draft.isPageReady = true;
        } else {
          draft.isLoading = false;
        }
        if (
          action.payload.actionName &&
          typeof initialState.formComplete[action.payload.actionName] ===
            "boolean"
        ) {
          draft.formComplete = {
            ...initialState.formComplete,
            [action.payload.actionName]: true,
          };
        } else {
          draft.formComplete = {
            ...initialState.formComplete,
          };
        }
        if (action.payload.message) {
          toast.success(cleanUp(action.payload.message), {
            onClose: toast.dismiss(),
          });
        }
        if (action.payload.resetForm) {
          draft.formsValue = initialState.formsValue;
          draft.formsValid = initialState.formsValid;
          draft.formComplete = initialState.formComplete;
          draft.checkoutJourney = initialState.checkoutJourney;
        }

        if (
          action.payload.actionName === "REGISTER" ||
          action.payload.actionName === "REQUEST_EMAIL_VERIFY"
        ) {
          draft.formsValue.verify.email = action.payload.newValues.email;
        } else if (action.payload.actionName === "FORGOT_PASSWORD") {
          draft.formsValue.resetPassword.email = action.payload.newValues.email;
        } else if (
          action.payload.impactedFields &&
          action.payload.impactedFields.length
        ) {
          action.payload.impactedFields.map(
            (fieldName) =>
              (draft[fieldName] = action.payload.newValues[fieldName])
          );
        }
        break;
      case FAILURE:
        if (action.payload.actionName === 'UPLOAD_MEETING') {
          toast.error(action.payload.error.message, {
            onClose: toast.dismiss(),
          });
        }
        if (action.payload.actionName === "CHECK_LOGIN_STATUS") {
          draft.isPageReady = true;
        } else {
          draft.isLoading = false;
        }
        if (action.payload.error && action.payload.error.message) {
          toast.error(action.payload.error.message, {
            onClose: toast.dismiss(),
          });
        }
        if (
          action.payload.impactedFields &&
          action.payload.impactedFields.length
        ) {
          action.payload.impactedFields.map(
            (fieldName) =>
              (draft[fieldName] = action.payload.newValues[fieldName])
          );
        }
        break;
      case FAILED_VALIDATION:
        draft.isLoading = false;
        if (action.payload.error && action.payload.error.message) {
          toast.warn(action.payload.error.message, {
            onClose: toast.dismiss(),
          });
        }
        {
          const matchingRowIndexes = Object.keys(
            action.payload.validationResponse
          );
          matchingRowIndexes.map(
            // eslint-disable-next-line no-return-assign
            (item) =>
              (draft.formsValid[action.payload.formName][item] =
                action.payload.validationResponse[item])
          );
        }
        break;
      case CHECK_LOGIN_STATUS:
        draft.isPageReady = false;
        break;
      case LOGOUT:
        draft.isLoading = true;
        break;
      case LOGIN:
        draft.isLoading = true;
        break;
      case REGISTER:
        draft.isLoading = true;
        break;
      case REQUEST_EMAIL_VERIFY:
        draft.isLoading = true;
        break;
      case VERIFY:
        draft.isLoading = true;
        break;
      case FORGOT_PASSWORD:
        draft.isLoading = true;
        break;
      case RESET_PASSWORD:
        draft.isLoading = true;
        break;
      case CHANGE_PASSWORD:
        draft.isLoading = true;
        break;
      case GET_PLAN:
        draft.isLoading = true;
        break;
      case ADD_TO_CART:
        draft.isLoading = true;
        break;
      case CHECKOUT:
        draft.isLoading = true;
        break;
      case CANCEL_TRANS:
        draft.isLoading = true;
        break;
      case PAYMENT_RESPONSE:
        draft.isLoading = true;
        break;
      case GET_PROFILE:
        draft.isLoading = true;
        break;
      case UPDATE_PROFILE:
        draft.isLoading = true;
        break;
      case DELETE_PROFILE:
        draft.isLoading = true;
        break;
      case GET_TRANSACTION:
        draft.isLoading = true;
        break;
      case GET_SUBSCRIPTION:
        draft.isLoading = true;
        break;
      case GET_USAGE:
        draft.isLoading = true;
        break;
      case GET_MY_MEETINGS:
        draft.isLoading = true;
        break;
      case GET_SHARED_MEETINGS:
        draft.isLoading = true;
        break;
      case DOWNLOAD_FILE:
        draft.isLoading = true;
        break;
      case RESET_FLOW:
        draft.isLoading = true;
        draft.formsValue = initialState.formsValue;
        draft.formsValid = initialState.formsValid;
        draft.formComplete = initialState.formComplete;
        draft.checkoutJourney = initialState.checkoutJourney;
        break;
    }
  });

export default appReducer;
