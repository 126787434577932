import React, { useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAppFormValue,
  makeSelectAppFormValid,
  makeSelectAppFormComplete,
} from "../App/selectors";
import { inputChange, register, resetFlow } from "../App/actions";

import FormComponent from "../../components/FormComponent";

import { formFields } from "./internalConfig";

const Register = ({
  isLogin,
  dRegister,
  dInputChange,
  formValue,
  formValid,
  formComplete,
  isPageReady,
  location,
  dResetFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isPageReady) {
      dResetFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, location.pathname]);
  if (isPageReady && isLogin) {
    return <Redirect to="/profile" />;
  }
  if (isPageReady && formComplete.REGISTER) {
    return <Redirect to="/verify" />;
  }

  return (
    <div className="container-fluid container-login100">
      <div className="wrap-login100">
        <div className="form-block">
          <h1>Register</h1>
          <FormComponent
            formFields={formFields}
            formValue={formValue.register}
            formValid={formValid.register}
            handleInputChange={dInputChange}
            submitLabel="Get Started"
            handleSubmit={dRegister}
          />
        </div>
        <div className="link-block">
          <NavLink to="/login">
            <strong>Already have an account?</strong>
            <span className="mossai-text"> Login</span>
          </NavLink>
          or
          <NavLink to="/request-email-verify">
            <strong>Email Not Verified?</strong>
            <span className="mossai-text"> Verify Now</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  formValue: makeSelectAppFormValue(),
  formValid: makeSelectAppFormValid(),
  formComplete: makeSelectAppFormComplete(),
});

function mapDispatchToProps(dispatch) {
  return {
    dResetFlow: () => {
      dispatch(resetFlow());
    },
    dInputChange: (value, fieldName) =>
      dispatch(inputChange("register", fieldName, value)),
    dRegister: () => {
      dispatch(register(formFields));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Register);
