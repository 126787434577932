import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { keyId, logoPath } from "../../config/_env";
import { moneyFormatter, cleanUp } from "../../utils/common";
import {
  makeSelectAppCheckoutJourney,
  makeSelectAppUserToken,
} from "../App/selectors";
import { paymentResponse, checkOut, cancelTrans } from "../App/actions";

const Cart = ({
  checkoutJourney,
  userToken,
  dPaymentResponse,
  dCancelTrans,
  dCheckOut,
  isPageReady,
  location,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  const handler = (response) => {
    dPaymentResponse({
      paymentStatus: "success",
      razorpayPaymentId: response.razorpay_payment_id,
    });
  };

  // eslint-disable-next-line no-undef
  const rzp1 = new Razorpay({
    key: keyId,
    currency: "INR",
    name: "Moss.AI",
    description: "Moss.AI",
    image: logoPath,
    amount: checkoutJourney.price * 100,
    order_id: checkoutJourney.paymentParterId,
    handler,
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#cb62fc",
    },
    modal: {
      ondismiss: () => dCancelTrans(),
    },
  });
  const confirmClick = () => {
    rzp1.open();
  };
  rzp1.on("payment.failed", () => {
    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  });
  
// Hided for stripe payment cancel If razor pay have to enable it.  
//  useEffect(() => {
//    if (checkoutJourney.ourTransId) {
//      confirmClick();
//    }
//    // eslint-disable-next-line react-hooks/exhaustive-deps
//  }, [checkoutJourney.ourTransId]);
  if (isPageReady && !checkoutJourney.inProgress) {
    return <Redirect to="/home#plans" />;
  }
  if (isPageReady && checkoutJourney.status === "success") {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="container">
      <div className="row content m-t-100 p-t-20 p-b-20">
        <div className="col-12">
          <h4 className="text-center">Selected Plan Details</h4>
          <div className="media activity-item">
            <div className="row g-0">
              <div className="col-4 plan-title">Name</div>
              <div className="col-4 plan-title">Duration</div>
              <div className="col-4 plan-title">Time</div>
            </div>
            <div className="row g-0">
              <div className="col-4 plan-text">
                <p>{cleanUp(checkoutJourney.name)}</p>
              </div>
              <div className="col-4 plan-text">
                <p>{moneyFormatter(checkoutJourney.price)}</p>
                <p>{cleanUp(checkoutJourney.duration)}</p>
              </div>
              <div className="col-4 plan-text">
                {checkoutJourney.isUnlimited ? (
                  <p>Unlimited</p>
                ) : (
                  <>
                    <p>
                      Total: {cleanUp(checkoutJourney.totalMinutes)} Minutes
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button className="btn btn-danger pull-right" onClick={dCheckOut}>
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  checkoutJourney: makeSelectAppCheckoutJourney(),
  userToken: makeSelectAppUserToken(),
});

function mapDispatchToProps(dispatch) {
  return {
    dCheckOut: () => {
      dispatch(checkOut());
    },
    dCancelTrans: () => {
      dispatch(cancelTrans());
    },
    dPaymentResponse: (transResponse) =>
      dispatch(paymentResponse(transResponse)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Cart);
